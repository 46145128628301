var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ib-company-register-vue"},[_c('Navigation',{attrs:{"show-language-switcher":true,"show-user-menu":false,"showLogin":true}}),(
      _vm.whitelabel === 'TD365' ||
      _vm.whitelabel === 'TDSouthAfrica' ||
      _vm.whitelabel === 'Blackstone' ||
      _vm.whitelabel === 'OneTradingMarkets' ||
      _vm.whitelabel === 'EagleGlobalMarkets' ||
      _vm.whitelabel === 'TradeCoreUK' ||
      _vm.whitelabel === 'GCCBrokers'
    )?_c('company-register-finsa'):_c('company-register-icm'),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }